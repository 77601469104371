import { graphql } from "gatsby";
import React from "react";
import AbschlussElemente from "../components/AbschlussElemente";
import BackgroundWrapper from "../components/BackgroundWrapper";
import FWThreeImages from "../components/FWThreeImages";
import { getIcon } from "../components/helpers/getIcon";
import IconGrid from "../components/IconGrid/IconGrid";
import IconItem from "../components/IconGrid/IconItem";
import Landing from "../components/Landing";
import Fliesstext from "../components/Text/Fliesstext";
import PostLandingText from "../components/Text/PostLandingText";
import SectionHeader from "../components/Text/SectionHeader";
import TextHalfGrid from "../components/Text/TextHalfGrid";
import DefaultLayout from "../layouts/default";

import { imgix } from "../styles/variables";
import ImgixImg from "../components/helpers/ImgixImg";

interface StandardSeitenProps {
  data: {
    cms: {
      entry: {
        title: string;
        id: number;
        siteId: number;
        metaBeschreibung: string;
        landingBild: [
          {
            url: string;
          }
        ];
        landingTitel: string;
        postLandingText: string;
        inhaltsseitenGrid: [
          {
            titel: string;
            text: string;
            bild: [
              {
                url: string;
              }
            ];
          }
        ];
        zwischenelementDreiBilder: [
          {
            url: string;
          }
        ];
        inhaltTitel: string;
        inhaltstextLinks: string;
        inhaltstextRechts: string;
        abschlussElemente: [
          {
            bild: [
              {
                url: string;
              }
            ];
            zitat: string;
            autor: string;
            buttonText: string;
            buttonLinkUrl: string;
            buttonLinkDatei: [
              {
                url: string;
              }
            ];
            buttonLinkTelefon: string;
            buttonLinkEmail: string;
            buttonIcon: string;
            inhaltstext: string;
          }
        ];
      };
    };
  };
}

export const StandardSeiteQuery = graphql`
  query ($id: CMS_QueryArgument, $siteId: CMS_QueryArgument) {
    cms {
      entry(id: [$id], siteId: [$siteId]) {
        title
        id
        siteId

        ... on CMS_main_standardSeite_Entry {
          metaBeschreibung
          landingBild {
            url
          }
          landingTitel
          postLandingText
          inhaltsseitenGrid {
            ... on CMS_inhaltsseitenGrid_reihe_BlockType {
              titel
              text
              bild {
                url
              }
            }
          }
          zwischenelementDreiBilder {
            url
          }
          inhaltTitel
          inhaltstextLinks
          inhaltstextRechts
          abschlussElemente {
            ... on CMS_abschlussElemente_bild_BlockType {
              bild {
                url
              }
            }
            ... on CMS_abschlussElemente_zitat_BlockType {
              zitat
              autor
            }
            ... on CMS_abschlussElemente_button_BlockType {
              buttonText
              buttonLinkUrl
              buttonLinkDatei {
                url
              }
              buttonLinkTelefon
              buttonLinkEmail
              buttonIcon
            }
            ... on CMS_abschlussElemente_zentrierterInhaltstext_BlockType {
              inhaltstext
            }
            ... on CMS_abschlussElemente_zentrierterInhaltstextBgColor_BlockType {
              titel
              text
            }
          }
        }
      }
    }
  }
`;

const StandardSeite: React.FC<StandardSeitenProps> = ({ data }) => {
  return (
    <DefaultLayout
      siteId={data.cms.entry.siteId}
      description={data.cms.entry.metaBeschreibung}
      title={data.cms.entry.title}
    >
      {data.cms.entry.landingBild && data.cms.entry.landingBild.length > 0 ? (
        <Landing
          title={data.cms.entry.landingTitel}
          image={data.cms.entry.landingBild[0].url}
        />
      ) : (
        ""
      )}

      {data.cms.entry.postLandingText ? (
        <PostLandingText text={data.cms.entry.postLandingText} />
      ) : (
        ""
      )}

      {data.cms.entry.inhaltsseitenGrid &&
      data.cms.entry.inhaltsseitenGrid.length > 0 ? (
        <BackgroundWrapper>
          <IconGrid>
            {data.cms.entry.inhaltsseitenGrid.map((item, index) => (
              <React.Fragment key={index}>
                <IconItem
                  icon={getIcon(data.cms.entry.siteId)}
                  title={item.titel}
                  text={item.text}
                />
                <div className="image" key={index}>
                  <ImgixImg
                    src={item.bild[0].url}
                    imgixParams={imgix.halfImage}
                  />
                </div>
              </React.Fragment>
            ))}
          </IconGrid>
        </BackgroundWrapper>
      ) : (
        ""
      )}
      {data.cms.entry.inhaltTitel ? (
        <SectionHeader title={data.cms.entry.inhaltTitel} />
      ) : (
        ""
      )}

      {data.cms.entry.zwischenelementDreiBilder &&
      data.cms.entry.zwischenelementDreiBilder > 0 ? (
        <FWThreeImages images={data.cms.entry.zwischenelementDreiBilder} />
      ) : (
        ""
      )}
      {data.cms.entry.inhaltstextLinks || data.cms.entry.inhaltstextRechts ? (
        <TextHalfGrid>
          <Fliesstext
            text={data.cms.entry.inhaltstextLinks}
            siteId={data.cms.entry.siteId}
          />
          <Fliesstext
            text={data.cms.entry.inhaltstextRechts}
            siteId={data.cms.entry.siteId}
          />
        </TextHalfGrid>
      ) : (
        ""
      )}

      {data.cms.entry.abschlussElemente.length > 0 ? (
        <AbschlussElemente
          content={data.cms.entry.abschlussElemente}
          siteId={data.cms.entry.siteId}
        />
      ) : (
        ""
      )}
    </DefaultLayout>
  );
};

export default StandardSeite;
