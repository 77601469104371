import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../styles/variables";
import Wrapper from "../Wrappers/Wrapper";

const GridDiv = styled.article`
  padding: 30px 0 40px;
  @media screen AND (min-width: ${breakpoints.tablet}px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    padding: 40px 0 90px;
  }
`;

interface TextHalfGridProps {
  padding?: boolean;
}

const TextHalfGrid: React.FC<TextHalfGridProps> = ({ children, padding }) => (
  <Wrapper>
    <GridDiv className={padding ? "padding" : ""}>{children}</GridDiv>
  </Wrapper>
);

export default TextHalfGrid;
